import React, { useEffect, useRef, useState } from 'react'
import Cube from 'src/iso/Cube'
import { shuffle } from 'src/iso/globals'



const EasingFunctions = {
  // no easing, no acceleration
  linear: (t: number) => t,
  // accelerating from zero velocity
  easeInQuad: (t: number) => t * t,
  // decelerating to zero velocity
  easeOutQuad: (t: number) => t * (2 - t),
  // acceleration until halfway, then deceleration
  easeInOutQuad: (t: number) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t),
  // accelerating from zero velocity
  easeInCubic: (t: number) => t * t * t,
  // decelerating to zero velocity
  easeOutCubic: (t: number) => --t * t * t + 1,
  // acceleration until halfway, then deceleration
  easeInOutCubic: (t: number) =>
    t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1,
  // accelerating from zero velocity
  easeInQuart: (t: number) => t * t * t * t,
  // decelerating to zero velocity
  easeOutQuart: (t: number) => 1 - --t * t * t * t,
  // acceleration until halfway, then deceleration
  easeInOutQuart: (t: number) =>
    t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t,
  // accelerating from zero velocity
  easeInQuint: (t: number) => t * t * t * t * t,
  // decelerating to zero velocity
  easeOutQuint: (t: number) => 1 + --t * t * t * t * t,
  // acceleration until halfway, then deceleration
  easeInOutQuint: (t: number) =>
    t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t,
}

declare global {
  interface Window {
    FPS: number
    VELOCITY: number
    CUBE_PANEL_SIZE: number
    GRADIENT_DEPTH: number
    INSET_GRADIENT_DEPTH: number
    DPR: number
    PULSE_SPEED: number
    POSITION_UNCERTAINTY: number
    MOVE_UNCERTAINTY: number
    CUBE_NUM: number
    BACKGROUND_COLOR: string
    COLORS: string[]
  }
}

const GLOBAL_KEYS = [
  'FPS',
  'VELOCITY',
  'CUBE_PANEL_SIZE',
  'GRADIENT_DEPTH',
  'INSET_GRADIENT_DEPTH',
  'DPR',
  'PULSE_SPEED',
  'POSITION_UNCERTAINTY',
  'MOVE_UNCERTAINTY',
  'CUBE_NUM',
  'BACKGROUND_COLOR',
] as const

let sinCounter = 0
let sinIncrease = (Math.PI * 2) / 400

let pulse = 0

const Iso: React.FC = () => {
  const ref = useRef<HTMLCanvasElement>(null)
  const [reset, setReset] = useState(0)
  const [cubes, setCubes] = useState<Cube[]>([])

  const settings = useRef({
    FPS: 60,
    VELOCITY: 0.3,
    CUBE_PANEL_SIZE: 300,
    GRADIENT_DEPTH: 300,
    INSET_GRADIENT_DEPTH: 100,
    DPR: (typeof window !== 'undefined' && window.devicePixelRatio) || 1,
    PULSE_SPEED: 0.00005,
    POSITION_UNCERTAINTY: 50,
    MOVE_UNCERTAINTY: 50,
    CUBE_NUM: 100,
    BACKGROUND_COLOR: '#fefefe',
    COLORS: ['19, 246, 254', '10, 251, 211', '255, 83, 186', '245, 211, 0'],
  })

  const { FPS, DPR, CUBE_NUM } = settings.current

  function render(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D) {
    pulse = Math.sin(sinCounter) / 4
    sinCounter += sinIncrease

    ctx.clearRect(0, 0, canvas.width, canvas.height)
    ctx.save()

    // change projection to isometric view
    ctx.translate(window.innerWidth / 2 + 200, window.innerHeight / 2 - 300)
    ctx.scale(1, 0.5)
    ctx.rotate((45 * Math.PI) / 180)

    for (const cube of cubes) {
      cube.update()
      cube.render()
    }

    ctx.restore() // back to orthogonal projection
  }

  useEffect(() => {
    if (cubes.length !== CUBE_NUM) {
      const width = window.innerWidth * DPR,
        height = window.innerHeight * DPR

      setTimeout(() => {
        setCubes([
          ...cubes,
          new Cube(
            (ref.current as HTMLCanvasElement).getContext(
              '2d'
            ) as CanvasRenderingContext2D,
            ((cubes.length + 1) / CUBE_NUM) * width,
            ((cubes.length + 1) / CUBE_NUM) * height
          ),
        ])
      }, 30)
    }
  }, [cubes.length])

  // SETUP
  useEffect(() => {
    if (ref.current && cubes.length !== CUBE_NUM) {
      const width = window.innerWidth * DPR,
        height = window.innerHeight * DPR

      setCubes([
        new Cube(
          (ref.current as HTMLCanvasElement).getContext(
            '2d'
          ) as CanvasRenderingContext2D,
          (1 / CUBE_NUM) * width,
          (1 / CUBE_NUM) * height
        ),
      ])
      // setCubes(
      //   Array.from(new Array(CUBE_NUM)).map(
      //     (_, idx) =>

      //   )
      // )
    }
  }, [ref.current])

  useEffect(() => {
    if (ref.current) {
      const cs = ref.current
      const ctx = cs.getContext('2d') as CanvasRenderingContext2D
      ctx.imageSmoothingEnabled = true

      cs.width = window.innerWidth * DPR
      cs.height = window.innerHeight * DPR

      ctx.scale(DPR, DPR)

      const intervals = [] as number[]

      render(cs, ctx)

      intervals.push(
        setInterval(() => {
          requestAnimationFrame(() => render(cs, ctx))
        }, 1000 / FPS)
      )

      intervals.push(
        setInterval(async () => {
          shuffle(cubes)
            .slice(0, Math.floor(CUBE_NUM / 2))
            .forEach(async (cube, idx) => {
              cube.move()
              await new Promise(r => setTimeout(r, idx % 5))
            })
        }, 3000)
      )

      return () => intervals.forEach(clearInterval)
    }
  }, [ref.current, reset, cubes])

  return (
    <div style={{ background: settings.current.BACKGROUND_COLOR }}>
      <canvas style={{ width: '100vw', height: '100vh' }} ref={ref} />
      <div style={{ position: 'absolute', right: 15, bottom: 75 }}>
        {GLOBAL_KEYS.map(global => {
          const value = settings.current[global]

          if (global === 'BACKGROUND_COLOR') {
            return (
              <div key={global} style={{ margin: '10px 0' }}>
                <label htmlFor={global}>{global}</label>
                <input
                  id={global}
                  defaultValue={value}
                  type="color"
                  onChange={e => {
                    settings.current[global] = e.target.value

                    setReset(reset + 1)
                  }}
                />
              </div>
            )
          }

          switch (typeof value) {
            case 'number':
              const valLog = Math.log10(value)
              return (
                <div key={global} style={{ margin: '10px 0' }}>
                  <label htmlFor={global}>{global}</label>
                  <input
                    id={global}
                    defaultValue={value}
                    type="number"
                    step={valLog < 0 ? Math.pow(10, Math.floor(valLog)) : 1}
                    onChange={e => {
                      if (
                        global === 'CUBE_NUM' &&
                        Number(e.target.value) > settings.current.CUBE_NUM
                      ) {
                        setCubes([
                          ...cubes,
                          new Cube(
                            ref.current?.getContext(
                              '2d'
                            ) as CanvasRenderingContext2D
                          ),
                        ])
                      }

                      settings.current[global] = Number(e.target.value)

                      setReset(reset + 1)
                    }}
                  />
                </div>
              )
            default:
              return
          }
        })}
      </div>
      <button
        style={{ position: 'absolute', right: 15, bottom: 15 }}
        onClick={() => {
          for (const cube of cubes) cube.reset()
          setReset(reset + 1)
        }}
      >
        Regenerate
      </button>
      <button
        style={{ position: 'absolute', right: 15, bottom: 45 }}
        onClick={async () => {
          for (const [idx, cube] of shuffle(cubes).entries()) {
            setTimeout(() => {
              cube.move()
            }, idx % 1000)
          }

          setReset(reset + 1)
        }}
      >
        Move
      </button>
    </div>
  )
}

export default Iso
