import { CubeSettings, EasingFunctions } from './globals'

const sinIncrease = (Math.PI * 2) / 200

export default class Cube {
  // Global position of the Cube
  x: number
  y: number

  // Scaling and sizing params
  // Scale is used to decide the size
  scalex: number
  scaley: number
  sizex: number
  sizey: number

  // Pointer to what coords the Cube should move
  tox: number
  toy: number

  // The color of the block
  color: string

  // Generate the pulsing effects etc
  tick: number
  pulse: number

  settings: CubeSettings
  ctx: CanvasRenderingContext2D

  constructor(
    ctx: CanvasRenderingContext2D,
    settings: CubeSettings,
    alongx?: number,
    alongy?: number
  ) {
    const { POSITION_UNCERTAINTY, COLORS, CUBE_PANEL_SIZE } = settings
    const x = alongx
        ? alongx + (Math.random() * 2 - 1) * POSITION_UNCERTAINTY
        : 40 *
          (Math.random() * POSITION_UNCERTAINTY - POSITION_UNCERTAINTY / 2),
      y = alongy
        ? alongy + (Math.random() * 2 - 1) * POSITION_UNCERTAINTY
        : 40 * (Math.random() * POSITION_UNCERTAINTY - POSITION_UNCERTAINTY / 2)
    this.x = x + 300
    this.y = y + 300

    this.scalex = (Math.random() * 10 + 1) / 10
    this.scaley = (Math.random() * 10 + 1) / 10

    this.sizex = CUBE_PANEL_SIZE * this.scalex
    this.sizey = CUBE_PANEL_SIZE * this.scaley

    this.tox = x //this.x
    this.toy = y //this.y
    this.color = COLORS[Math.floor(Math.random() * COLORS.length)]

    this.tick = Math.floor(Math.random() * 100)
    this.pulse = sinIncrease

    this.settings = settings
    this.ctx = ctx
  }

  reset() {
    const { POSITION_UNCERTAINTY, COLORS, CUBE_PANEL_SIZE } = this.settings
    this.x =
      40 * (Math.random() * POSITION_UNCERTAINTY - POSITION_UNCERTAINTY / 2)
    this.y =
      40 * (Math.random() * POSITION_UNCERTAINTY - POSITION_UNCERTAINTY / 2)

    this.scalex = (Math.random() * 10 + 1) / 10
    this.scaley = (Math.random() * 10 + 1) / 10

    this.sizex = CUBE_PANEL_SIZE * this.scalex
    this.sizey = CUBE_PANEL_SIZE * this.scaley

    this.tox = this.x
    this.toy = this.y
    this.color = COLORS[Math.floor(Math.random() * COLORS.length)]
    this.tick = 0
  }

  update() {
    const { VELOCITY, CUBE_PANEL_SIZE } = this.settings

    ++this.tick
    this.pulse = Math.sin(this.tick * sinIncrease) / 5

    const dx = this.tox - this.x,
      dy = this.toy - this.y,
      vx = dx > 0 ? dx * VELOCITY : dx * VELOCITY,
      vy = dy > 0 ? dy * VELOCITY : dy * VELOCITY

    this.sizex = CUBE_PANEL_SIZE * this.scalex
    this.sizey = CUBE_PANEL_SIZE * this.scaley

    this.x = this.x + this.pulse * 0.05 + vx
    this.y = this.y + this.pulse * 0.05 + vy

    // If our delta is sufficiently small we set the coords to have reached
    // their destination
    if (Math.abs(dx) < 1e-2) this.tox = this.x
    if (Math.abs(dy) < 1e-2) this.toy = this.y
  }

  render() {
    const { GRADIENT_DEPTH, INSET_GRADIENT_DEPTH } = this.settings
    const { ctx, x, y, sizex, sizey, color } = this

    ctx.save()
    ctx.strokeStyle = `rgb(${color})`
    ctx.fillStyle = `rgba(${color}, 0.9)`
    ctx.fillRect(x, y, sizex, sizey)
    ctx.strokeRect(x, y, sizex, sizey)
    ctx.restore()

    // Draw the long faces
    this.drawFaces(
      ctx,
      x,
      y + sizey,
      x + sizex,
      y,
      sizex,
      sizey,
      GRADIENT_DEPTH,
      color
    )

    // Draw the inset shadow
    this.drawFaces(
      ctx,
      x,
      y,
      x,
      y,
      sizex,
      sizey,
      INSET_GRADIENT_DEPTH,
      color,
      false
    )
  }

  move() {
    const direction = Math.random() < 0.5 ? -1 : 1
    const toss = Math.random()
    const moveDelta =
      Math.random() * this.settings.MOVE_UNCERTAINTY -
      this.settings.MOVE_UNCERTAINTY / 2
    if (toss < 0.33) {
      this.toy = this.y + direction * moveDelta
    } else if (toss >= 0.33 && toss < 0.66) {
      this.tox = this.x + direction * moveDelta
    } else if (toss >= 0.66) {
      this.tox = this.x + direction * moveDelta
      this.toy = this.y + direction * moveDelta
    }
  }

  drawFaces(
    ctx: CanvasRenderingContext2D,
    originx0: number,
    originy0: number,
    originx1: number,
    originy1: number,
    actualSizeX: number,
    actualSizeY: number,
    gradienDepth: number,
    color: string,
    withPulse = true
  ) {
    /** START FACE DRAW ------------------ */
    ctx.save()
    ctx.translate(originx0, originy0)
    ctx.transform(1, 0, 1, 1, 1, 1)

    let grd = ctx.createLinearGradient(0, 0, 0, gradienDepth)
    for (let i = 0; i < gradienDepth; i += 5) {
      const fraction = i / gradienDepth
      grd.addColorStop(
        fraction,
        `rgba(${color}, ${1 -
          EasingFunctions['easeOutQuart'](fraction) +
          (withPulse ? this.pulse : 0)})`
      )
    }

    ctx.beginPath()
    ctx.moveTo(0, 0)
    ctx.lineTo(actualSizeX + 1, 0)
    ctx.lineTo(actualSizeX + 1, gradienDepth)
    ctx.lineTo(0, gradienDepth)
    ctx.closePath()
    ctx.fillStyle = grd
    ctx.fill()

    ctx.restore()
    ctx.save()

    ctx.translate(originx1, originy1)
    ctx.transform(1, 1, 0, 1, 1, 1)

    grd = ctx.createLinearGradient(0, 0, gradienDepth, 0)
    for (let i = 0; i < gradienDepth; i += 5) {
      const fraction = i / gradienDepth
      grd.addColorStop(
        fraction,
        `rgba(${color}, ${1 -
          EasingFunctions['easeOutQuart'](fraction) +
          (withPulse ? this.pulse : 0)})`
      )
    }

    ctx.beginPath()
    ctx.moveTo(0, 0)
    ctx.lineTo(0, actualSizeY + 1)
    ctx.lineTo(gradienDepth, actualSizeY + 1)
    ctx.lineTo(gradienDepth, 0)
    ctx.closePath()
    ctx.fillStyle = grd
    ctx.fill()

    ctx.restore()
    /** END FACE DRAW ------------ */
  }
}
